export default [

  {
    path: '/cotizacion',
    name: 'Cotizacion',
    component: () => import( /* webpackChunkName: "Cotizacion" */ '@/views/Finanzas/Cotizacion/Cotizacion.vue'),
  },
  {
    path: '/cotizacion/add',
    name: 'Cotizacion Add',
    component: () => import( /* webpackChunkName: "Cotizacion Add" */ '@/views/Finanzas/Cotizacion/CreateV2.vue'),
  },
  {
    path: '/finanzas',
    name: 'Finanzas',
    component: () => import( /* webpackChunkName: "Finanza" */ '@/views/Finanzas/Root.vue'),
    children: [
      {
        path: 'mifinanza',
        name: 'Mi Finanza',
        meta: {
          tab: 0
        },
        component: () => import( /* webpackChunkName: "Mi Finanza" */ '@/views/Finanzas/Operaciones/Inicio/Inicio.vue'),
      },
      {
        path: 'operaciones',
        name: 'Operaciones',
        component: () => import( /* webpackChunkName: "Finanza Operaciones" */ '@/views/Finanzas/Operaciones/Root.vue'),
        children: [
          {
            path: 'tipo',
            name: 'Tipo Finanza',
            meta: {
              tab: 0
            },
            component: () => import( /* webpackChunkName: "Finanza Tipo" */ '@/views/Finanzas/Operaciones/Tipo/Tipo.vue'),
          },
          {
            path: 'tiempo',
            name: 'Tiempo Finanza',
            meta: {
              tab: 0
            },
            component: () => import( /* webpackChunkName: "Finanza Tipo" */ '@/views/Finanzas/Operaciones/Tiempo/Tiempo.vue'),
          },
          {
            path: 'cuenta',
            name: 'Cuenta Finanza',
            meta: {
              tab: 0
            },
            component: () => import( /* webpackChunkName: "Finanza Cuenta" */ '@/views/Finanzas/Operaciones/Cuenta/Cuenta.vue'),
          },
          {
            path: 'grupo',
            name: 'Grupo Finanza',
            meta: {
              tab: 0
            },
            component: () => import( /* webpackChunkName: "Finanza Grupo" */ '@/views/Finanzas/Operaciones/Grupo/Grupo.vue'),
          },
          {
            path: 'categoria',
            name: 'Categoria Finanza',
            meta: {
              tab: 0
            },
            component: () => import( /* webpackChunkName: "Finanza Categoria" */ '@/views/Finanzas/Operaciones/Categoria/Categoria.vue'),
          }
        ]
      },
      {
        path: 'transacciones',
        name: 'Finanzas Transacciones',
        component: () => import( /* webpackChunkName: "Finanza Transacciones" */ '@/views/Finanzas/Operaciones/Root.vue'),
        children: [
          {
            path: 'movimiento',
            name: 'Movimiento',
            meta: {
              tab: 0
            },
            component: () => import( /* webpackChunkName: "Finanza Movimiento" */ '@/views/Finanzas/Operaciones/Movimiento/Movimiento.vue'),
          }
        ]
      },
      {
        path: 'reportes',
        name: 'Finanzas Reportes',
        component: () => import( /* webpackChunkName: "Finanza Transacciones" */ '@/views/Finanzas/Operaciones/Root.vue'),
        children: [
          {
            path: 'gastos',
            name: 'Gastos',
            meta: {
              tab: 0
            },
            component: () => import(/* webpackChunkName: "Finanza Reporte Gastos" */ '@/views/Finanzas/Operaciones/Reporte/Gastos.vue'),
          },
          {
            path: 'ingresos',
            name: 'Ingresos',
            meta: {
              tab: 0
            },
            component: () => import(/* webpackChunkName: "Finanza Reporte Ingresos" */ '@/views/Finanzas/Operaciones/Reporte/Ingresos.vue'),
          },
          {
            path: 'presupuestos',
            name: 'Presupuestos',
            meta: {
              tab: 0
            },
            component: () => import(/* webpackChunkName: "Finanza Reporte Presupuestos" */ '@/views/Finanzas/Operaciones/Reporte/Presupuestos.vue')
          },
          {
            path: 'flujos',
            name: 'Flujo de Caja',
            meta: {
              tab: 0
            },
            component: () => import(/* webpackChunkName: "Finanza Reporte Presupuestos" */ '@/views/Finanzas/Operaciones/Reporte/FlujoCajas.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/reportecomercial',
    name: 'Reportes Comercial',
    component: () => import( /* webpackChunkName: "Informe Compra Venta" */ '@/views/Finanzas/Informe/MainComercial.vue'),
    children: [
      {
        path: 'estadoresultado',
        meta: {
          tab: 0
        },
        component: () => import( /* webpackChunkName: "List" */ '@/views/Finanzas/Informe/EstadoResultado.vue')
      },
      {
        path: 'estadoresultado2',
        meta: {
          tab: 0
        },
        component: () => import( /* webpackChunkName: "List" */ '@/views/Finanzas/Informe/EstadoResultado2.vue')
      },
      {
        path: 'libroventa',
        meta: {
          tab: 0
        },
        component: () => import( /* webpackChunkName: "List" */ '@/views/Venta/Reporte/LibroVenta.vue')
      },
      {
        path: 'librocompra',
        meta: {
          tab: 0
        },
        component: () => import( /* webpackChunkName: "List" */ '@/views/Compra/Reporte/LibroCompra.vue')
      }
    ]
  }
]


import { get, post, put, del } from "@/services/api/api.service";
import { 
    SET_LOADING_TIPO,
    SET_TIPO,
    url,
    SET_LOADING_CUENTA,
    SET_CUENTA,
    url2,
    SET_LOADING_GRUPO,
    SET_GRUPO,
    url3,
    SET_LOADING_CATEGORIA,
    SET_CATEGORIA,
    url4,
    SET_LOADING_MOVIMIENTO,
    SET_MOVIMIENTO,
    url5,
    SET_LOADING_SALDO,
    SET_SALDO,
    url6,
    SET_LOADING_MOVIMIENTO_CUENTA,
    SET_MOVIMIENTO_CUENTA,
    url7,
    SET_LOADING_GASTO_MENSUAL,
    url8,
    SET_GASTO_MENSUAL,
    SET_LOADING_TIEMPO,
    url9,
    SET_TIEMPO,
    SET_TRANSFERENCIA,
    SET_LOADING_PRESUPUESTO_DISPONIBLE,
    url10,
    SET_PRESUPUESTO_DISPONIBLE,
    SET_LOADING_FLUJO_CAJA,
    url11,
    SET_FLUJO_CAJA,
    SET_LOADING_FLUJO_YEAR,
    url12,
    SET_FLUJO_YEAR,
} from "./constants";
export default {
    async fetchTipo({ commit, dispatch }) {
        commit(SET_LOADING_TIPO, true);
        try {
            const response = await get(url);
            commit(SET_TIPO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_TIPO, false);
            throw e;
        }
        commit(SET_LOADING_TIPO, false);
    },

    async fetchCuenta({ commit, dispatch }) {
        commit(SET_LOADING_CUENTA, true);
        try {
            const response = await get(url2);
            commit(SET_CUENTA, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_CUENTA, false);
            throw e;
        }
        commit(SET_LOADING_CUENTA, false);
    },

    async setCuenta({ commit, dispatch }, data) {
        commit(SET_LOADING_CUENTA, true);
        const response = await post(url2, data);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_CUENTA, false);
        return response;
    },

    async setCuentaUpdate({ commit, dispatch }, {data, id}) {
        commit(SET_LOADING_CUENTA, true);
        const response = await put(`${url2}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_CUENTA, false);
        return response;
    },

    async setCuentaDelete({ commit, dispatch }, id) {
        commit(SET_LOADING_CUENTA, true);
        const response = await del(`${url2}${id}/`);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_CUENTA, false);
        return response;
    },

    async fetchGrupo({commit, dispatch}) {
        commit(SET_LOADING_GRUPO, true);
        try {
            const response = await get(url3);
            commit(SET_GRUPO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_GRUPO, false);
            throw e;
        }
        commit(SET_LOADING_GRUPO, false);
    },

    async setGrupo({ commit, dispatch }, data) {
        commit(SET_LOADING_GRUPO, true);
        const response = await post(url3, data);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_GRUPO, false);
        return response;
    },

    async setGrupoUpdate({ commit, dispatch }, {data, id}) {
        commit(SET_LOADING_GRUPO, true);
        const response = await put(`${url3}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_GRUPO, false);
        return response;
    },

    async setGrupoDelete({ commit, dispatch }, id) {
        commit(SET_LOADING_GRUPO, true);
        const response = await del(`${url3}${id}/`);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_GRUPO, false);
        return response;
    },

    async fetchCategoria({commit, dispatch}) {
        commit(SET_LOADING_CATEGORIA, true);
        try {
            const response = await get(url4);
            commit(SET_CATEGORIA, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_CATEGORIA, false);
            throw e;
        }
        commit(SET_LOADING_CATEGORIA, false);
    },

    async setCategoria({ commit, dispatch }, data) {
        commit(SET_LOADING_CATEGORIA, true);
        const response = await post(url4, data);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_CATEGORIA, false);
        return response;
    },

    async setCategoriaUpdate({ commit, dispatch }, {data, id}) {
        commit(SET_LOADING_CATEGORIA, true);
        const response = await put(`${url4}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_CATEGORIA, false);
        return response;
    },

    async setCategoriaDelete({ commit, dispatch }, id) {
        commit(SET_LOADING_CATEGORIA, true);
        const response = await del(`${url4}${id}/`);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_CATEGORIA, false);
        return response;
    },

    async fetchMovimiento({commit, dispatch}, type) {
        commit(SET_LOADING_MOVIMIENTO, true);
        try {
            const response = await get(`${url5}?type=${type}`);
            if (type=='movimiento') {
                commit(SET_MOVIMIENTO, response);
            } else {
                commit(SET_TRANSFERENCIA, response);
            }
            
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_MOVIMIENTO, false);
            throw e;
        }
        commit(SET_LOADING_MOVIMIENTO, false);
    },

    async setMovimiento({ commit, dispatch }, data) {
        commit(SET_LOADING_MOVIMIENTO, true);
        const response = await post(url5, data);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_MOVIMIENTO, false);
        return response;
    },

    async setMovimientoUpdate({ commit, dispatch }, {data, id}) {
        commit(SET_LOADING_MOVIMIENTO, true);
        const response = await put(`${url5}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_MOVIMIENTO, false);
        return response;
    },

    async setMovimientoDelete({ commit, dispatch }, id) {
        commit(SET_LOADING_MOVIMIENTO, true);
        const response = await del(`${url5}${id}/`);
        dispatch('snackbar/setNotification', response, { root: true });
        commit(SET_LOADING_MOVIMIENTO, false);
        return response;
    },

    async fetchSaldo({commit, dispatch}) {
        commit(SET_LOADING_SALDO, true);
        try {
            const response = await get(url6);
            commit(SET_SALDO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_SALDO, false);
            throw e;
        }
        commit(SET_LOADING_SALDO, false);
    },

    async fetchMovimientoCuenta({commit, dispatch}, {id, month, year}) {
        commit(SET_LOADING_MOVIMIENTO_CUENTA, true);
        try {
            const response = await get(`${url7}${id}/${month}/${year}`);
            commit(SET_MOVIMIENTO_CUENTA, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_MOVIMIENTO_CUENTA, false);
            throw e
        }
        commit(SET_LOADING_MOVIMIENTO_CUENTA, false);
    },

    async fetchGastoMensual({commit, dispatch}, {month, year}) {
        commit(SET_LOADING_GASTO_MENSUAL, true);
        try {
            const response = await get(`${url8}${month}/${year}`);
            commit(SET_GASTO_MENSUAL, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_GASTO_MENSUAL, false);
            throw e;
        }
        commit(SET_LOADING_GASTO_MENSUAL, false);
    },

    async fetchTiempo({commit, dispatch}) {
        commit(SET_LOADING_TIEMPO, true);
        try {
            const response = await get(url9);
            commit(SET_TIEMPO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_TIPO, false);
            throw e;
        }
        commit(SET_LOADING_TIEMPO, false);
    },

    async fetchPresupuestoDisponible({commit, dispatch}, {categoria}) {
        commit(SET_LOADING_PRESUPUESTO_DISPONIBLE, true);
        try {
            const response = await get(`${url10}${categoria}/`);
            commit(SET_PRESUPUESTO_DISPONIBLE, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_PRESUPUESTO_DISPONIBLE, false);
            throw e;
        }
        commit(SET_LOADING_PRESUPUESTO_DISPONIBLE, false);
    },

    async fetchFlujoCaja({commit, dispatch}, {year}) {
        commit(SET_LOADING_FLUJO_CAJA, true);
        try {
            const response = await get(`${url11}${year}/`);
            commit(SET_FLUJO_CAJA, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_FLUJO_CAJA, false);
            throw e;
        }
        commit(SET_LOADING_FLUJO_CAJA, false);
    },

    async fetchFlujoYear({commit, dispatch}) {
        commit(SET_LOADING_FLUJO_YEAR, true);
        try {
            const response = await get(`${url12}`);
            commit(SET_FLUJO_YEAR, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, {root: true});
            commit(SET_LOADING_FLUJO_YEAR, false);
            throw e;
        }
        commit(SET_LOADING_FLUJO_YEAR, false);
    }
}
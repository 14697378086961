export default {
    getTipo: (state) => {
        return state.tipo
    },
    isLoadingTipo: (state) => {
        return state.loadingTipo
    },
    getCuenta: (state) => {
        return state.cuenta
    },
    isLoadingCuenta: (state) => {
        return state.loadingCuenta
    },
    getGrupo: (state) => {
        return state.grupo
    },
    isLoadingGrupo: (state) => {
        return state.loadingGrupo
    },
    getCategoria: (state) => {
        return state.categoria
    },
    isLoadingCategoria: (state) => {
        return state.loadingCategoria
    },
    getMovimiento: (state) => {
        return state.movimiento
    },
    getTransferencia: (state) => {
        return state.transferencia
    },
    isLoadingMovimiento: (state) => {
        return state.loadingMovimiento
    },
    getSaldo: (state) => {
        return state.saldo
    },
    isLoadingSaldo: (state) => {
        return state.loadingSaldo
    },
    getMovimientoCuenta: (state) => {
        return state.movimientoCuenta
    },
    isLoadingMovimientoCuenta: (state) => {
        return state.loadingMovimientoCuenta
    },
    getGastoMensual: (state) => {
        return state.gastoMensual
    },
    isLoadingGastoMensual: (state) => {
        return state.loadingGastoMensual
    },
    getTiempo: (state) => {
        return state.tiempo
    },
    isLoadingTiempo: (state) => {
        return state.loadingTiempo
    },
    getPresupuestoDisponible: (state) => {
        return state.presupuestoDisponible
    },
    isLoadingPresupuestoDisponible: (state) => {
        return state.loadingPresupuestoDisponible
    },
    getFlujoCaja: (state) => {
        return state.flujoCaja
    },
    isLoadingFlujoCaja: (state) => {
        return state.loadingFlujoCaja
    },
    getFlujoYear: (state) => {
        return state.flujoYear
    },
    isLoadingFlujoYear: (state) => {
        return state.loadingFlujoYear
    }
}

import {
    AGENDA,
    DIARIO_DE_CAJA,
    CAJA,
    BANCO,
    CUENTA_BANCO,
    ZONAS_CULTIVO,
    ZAFRA, INSUMOS,
    VENTA_GRANOS,
    REPORTE_AGRICULTURA,
    EMITIR_CHEQUE,
    TRANSFERENCIA_CAJA_CAJA,
    TRANSFERENCIA_CAJA_BANCO,
    TRANSFERENCIA_BANCO_CAJA,
    TRANSFERENCIA_BANCO_BANCO,
    INFORME_CAJA_CUENTA,
    AJUSTE_CAJA,
    AJUSTE_CUENTA_BANCO,
    TARJETA_CREDITO,
    ANIMAL,
    CELO,
    INSEMINACION,
    PALPACION,
    SECADO,
    PARTO,
    ABORTO,
    ANALISIS,
    ENFERMEDAD,
    MEDICACION,
    MUERTE,
    RECHAZO,
    PRODUCCION_LECHE,
    COMPRA,
    EGRESO,
    NOTA_CREDITO_COMPRA,
    EXTRACTO_PROVEEDOR,
    PAGO_CUENTA,
    SUCURSAL,
    ROL_USUARIO,
    EMPRESA,
    USUARIO,
    CUENTA,
    DEPOSITO,
    CATEGORIA_ITEM,
    MARCA,
    CONCEPTO,
    PROPIEDAD_QUIMICA,
    ITEM,
    TIPO_CUENTA_BANCO,
    MOTIVO_TRANSLADO,
    MOTIVO_AJUSTE,
    TIPO_AJUSTE,
    PERFIL_FINANCIAMIENTO,
    CONFIG_IMPRESION,
    PUNTO_EXPEDICION,
    PUNTO_VENTA,
    ESTABLECIMIENTO,
    TIMBRADO,
    TURNO_ORDENO,
    TIPO_ENFERMEDAD,
    MOTIVO_MUERTE,
    DIAGNOSTICO_UTERO,
    CAUSA_RECHAZO,
    CAUSA_ABORTO,
    MEDICACION_MOTIVO,
    VIA_APLICACION,
    VETERINARIO,
    TIPO_ANALISIS,
    CAUSA_NO_INSEMINACION,
    TIPO_RESPONSABLE,
    TIPO_PARTO,
    ROL,
    RESPONSABLE,
    RAZA,
    PROCEDENCIA,
    LOTE,
    ESTADO_CRIA,
    COSTO_NACIMIENTO,
    AJUSTE_STOCK,
    STOCK,
    COTIZACION,
    APERTURA_CAJA,
    VENTA,
    COBRO_CUENTA,
    EXTRACTO_CLIENTE,
    NOTA_CREDITO_VENTA,
    RUBRO,
    CUENTA_DIARIO,
    REFERENCIA,
    DIVISION,
    REPORTE_COMERCIAL,
    FUNCIONARIO,
    SECTOR,
    NIVEL,
    CONCEPTO_SALARIAL,
    ESTADO_ASISTENCIA,
    RELOJ_BIOMETRICO,
    ASISTENCIA,
    JUSTIFICACIONES,
    PAGO_SALARIO,
    CONFIG_RRHH,
    POLIGONO_MARCACION,
    OBJETIVO,
    VENTA_GANADO,
    TERMO_SEMEN,
    CANJE_GRANO,
    PREPARTO,
    RACION,
    TIPO_GIMNASIO,
    TRANSFERENCIA,
    MAQUINARIA,
    FABRICANTE,
    TIPO_MANTENIMIENTO_MAQUINARIA,
    MANTENIMIENTO_MAQUINARIA,
    ASIGNACION_MAQUINARIA,
    TIPO_GASTO_MAQUINARIA,
    GASTO_MAQUINARIA,
    TRANSPORTE_MAQUINARIA,
    SALARIO_MAQUINARIA,
    CONFIG_MAQUINARIA,
    FINANZA,
    ALIMENTACION
} from '../../constants/forms';
import {
    PUEDE_GENERAR_REPORTE,
    PUEDE_AGREGAR,
    SALDO_CAJA_CUENTA,
    HISTORIAL_TRANSFERENCIAS,
    LIBRO_COMPRA,
    LIBRO_VENTA,
    ESTADO_RESULTADO,
    PUEDE_LISTAR
} from '../../constants/permissions';

let permisos = [];
const verificationPermission = (tabla, permission) => {
    if (permission && permisos[tabla])
        return permisos[tabla].permiso[permission];

    if (tabla === "full") return true;
    if (permisos[tabla] != undefined) {
        let hasPermission = false;
        Object.keys(permisos[tabla].permiso).forEach(key => {
            if (permisos[tabla].permiso[key]) hasPermission = true
        })
        return hasPermission
    }
    return false;
}

export const navbar = (permissionList) => {
    permisos = permissionList;
    let array = [
        { id: 1, name: "Dashboard", icon: "dashboard", route: "/", permission: true },
        { id: 2, name: "Agenda", icon: "event", route: "/agenda", permission: verificationPermission(AGENDA) },
        {
            id: 3,
            name: "Diario de Caja",
            children: [
                {
                    id: 301,
                    icon: 'list_alt',
                    name: 'Asiento',
                    route: "/diariocaja/asiento",
                    permission: verificationPermission(DIARIO_DE_CAJA),
                },
                {
                    id: 302,
                    icon: 'list_alt',
                    name: 'Referencia',
                    route: "/diariocaja/referencia",
                    permission: verificationPermission(REFERENCIA),
                },
                {
                    id: 303,
                    icon: 'list_alt',
                    name: 'Rubro',
                    route: "/diariocaja/rubro",
                    permission: verificationPermission(RUBRO),
                },
                {
                    id: 304,
                    icon: 'list_alt',
                    name: 'Division',
                    route: "/diariocaja/division",
                    permission: verificationPermission(DIVISION),
                },
                {
                    id: 305,
                    icon: 'list_alt',
                    name: 'Cuenta Diario Caja',
                    route: "/diariocaja/cuentadiario",
                    permission: verificationPermission(CUENTA_DIARIO),
                },
                {
                    id: 306,
                    icon: 'list_alt',
                    name: 'Reportes',
                    route: "/diariocaja/reporteavanzado",
                    permission: verificationPermission(DIARIO_DE_CAJA, PUEDE_GENERAR_REPORTE),
                },
            ]
        },

        {
            id: 4,
            name: "Cajas y Bancos",
            children: [
                {
                    id: 402,
                    name: "Saldos",
                    icon: 'monetization_on',
                    route: "/cajabanco",
                    permission: verificationPermission(INFORME_CAJA_CUENTA, SALDO_CAJA_CUENTA)
                },
                {
                    id: 403,
                    name: "Operaciones",
                    children: [
                        {
                            id: 4301,
                            icon: 'payments',
                            name: 'Emitir Cheque',
                            route: '/cajabanco/transaccion/emitircheque',
                            permission: verificationPermission(EMITIR_CHEQUE)
                        },
                        {
                            id: 4302,
                            icon: 'payments',
                            name: 'Desembolso y Confirmacion de Cheque',
                            route: '/cajabanco/transaccion/confirmacioncheque',
                            permission: verificationPermission(EMITIR_CHEQUE, PUEDE_AGREGAR)
                        }
                    ]
                },
                {
                    id: 404,
                    name: "Transferencias",
                    children: [
                        {
                            id: 4401,
                            icon: 'reply',
                            name: 'Caja a Caja',
                            route: '/cajabanco/transferencia/cajacaja',
                            permission: verificationPermission(TRANSFERENCIA_CAJA_CAJA)
                        },
                        {
                            id: 4402,
                            icon: 'reply',
                            name: 'Caja a Banco',
                            route: '/cajabanco/transferencia/cajabanco',
                            permission: verificationPermission(TRANSFERENCIA_CAJA_BANCO)
                        },
                        {
                            id: 403,
                            icon: 'reply',
                            name: 'Banco a Caja',
                            route: '/cajabanco/transferencia/bancocaja',
                            permission: verificationPermission(TRANSFERENCIA_BANCO_CAJA)
                        },
                        {
                            id: 4404,
                            icon: 'reply',
                            name: 'Banco a Banco',
                            route: '/cajabanco/transferencia/bancobanco',
                            permission: verificationPermission(TRANSFERENCIA_BANCO_BANCO)
                        },
                        {
                            id: 4405,
                            icon: 'history',
                            name: 'Historial de Transferencias',
                            route: '/cajabanco/transferencia/historial',
                            permission: verificationPermission(INFORME_CAJA_CUENTA, HISTORIAL_TRANSFERENCIAS)
                        }
                    ]
                },
                {
                    id: 405,
                    name: "Ajustes de Cuentas",
                    children: [
                        {
                            id: 4501,
                            icon: 'build',
                            name: 'Ajuste de Caja',
                            route: '/cajabanco/ajuste/caja',
                            permission: verificationPermission(AJUSTE_CAJA)
                        },
                        {
                            id: 4502,
                            icon: 'build',
                            name: 'Ajuste de Cuenta Banco',
                            route: '/cajabanco/ajuste/cuentabancaria',
                            permission: verificationPermission(AJUSTE_CUENTA_BANCO)
                        }
                    ]
                },
                {
                    id: 406,
                    name: "Mantenimientos",
                    children: [
                        {
                            id: 4601,
                            icon: 'settings',
                            name: 'Caja',
                            route: '/cajabanco/mantenimiento/caja',
                            permission: verificationPermission(CAJA)
                        },
                        {
                            id: 4602,
                            icon: 'settings',
                            name: 'Banco',
                            route: '/cajabanco/mantenimiento/banco',
                            permission: verificationPermission(BANCO)
                        },
                        {
                            id: 4603,
                            icon: 'settings',
                            name: 'Cuenta Banco',
                            route: '/cajabanco/mantenimiento/cuentabanco',
                            permission: verificationPermission(CUENTA_BANCO)
                        },
                        {
                            id: 4604,
                            icon: 'settings',
                            name: 'Chequera',
                            route: '/cajabanco/mantenimiento/chequera',
                            permission: verificationPermission(CUENTA_BANCO)
                        },
                        {
                            id: 4605,
                            icon: 'settings',
                            name: 'Tarjeta de Credito',
                            route: '/cajabanco/mantenimiento/tarjetacredito',
                            permission: verificationPermission(TARJETA_CREDITO)
                        }
                    ]
                }
            ]
        },
        {
            id: 5,
            name: "Agricultura",
            children:
                [
                    { name: "Mapa de Lotes", id: 500, icon: 'eco', route: "/agricultura/campo", permission: verificationPermission(ZAFRA) },
                    { name: "Sensores", id: 506, icon: 'eco', route: "/agricultura/sensor", permission: verificationPermission(ZONAS_CULTIVO) },
                    { name: "Campaña", id: 501, icon: 'eco', route: "/agricultura/campana", permission: verificationPermission(ZONAS_CULTIVO) },
                    {
                        id: 502,
                        name: 'Transacciones',
                        children:
                            [
                                { name: "Insumos", id: 5201, icon: 'eco', route: "/agricultura/transaccion/insumos", permission: verificationPermission(INSUMOS) },
                                { name: "Venta de Granos", id: 5202, icon: 'eco', route: "/agricultura/transaccion/ventagrano", permission: verificationPermission(VENTA_GRANOS) },
                                { name: "Preventa de Granos", id: 5203, icon: 'eco', route: "/agricultura/transaccion/preventagrano", permission: verificationPermission(VENTA_GRANOS) },
                                { name: "Canje Simple", id: 5205, icon: 'eco', route: "/agricultura/transaccion/canjesimple", permission: verificationPermission(CANJE_GRANO) },
                                { name: "Canje Avanzado", id: 5204, icon: 'eco', route: "/agricultura/transaccion/canje", permission: verificationPermission(CANJE_GRANO) },
                                { name: "Recetario", id: 5206, icon: 'eco', route: "/agricultura/transaccion/recetario", permission: verificationPermission(ZAFRA) },
                                { name: "Reajuste", id: 5207, icon: 'eco', route: "/agricultura/transaccion/reajuste", permission: verificationPermission(ZAFRA) },
                            ]
                    },
                    {
                        id: 503,
                        name: 'Reportes',
                        children:
                            [
                                { name: "Avanzado", id: 5301, icon: 'eco', route: "/agricultura/report", permission: verificationPermission(REPORTE_AGRICULTURA) },
                                { name: "Lote", id: 5302, icon: 'eco', route: "/agricultura/report/lote", permission: verificationPermission(REPORTE_AGRICULTURA) },
                                { name: "Indice Historico", id: 5303, icon: 'eco', route: "/agricultura/report/index", permission: verificationPermission(REPORTE_AGRICULTURA) },
                                { name: "Estimacion de Costos", id: 5304, icon: 'eco', route: "/agricultura/report/costos", permission: verificationPermission(REPORTE_AGRICULTURA) },
                                { name: "Senave", id: 5305, icon: 'eco', route: "/agricultura/report/senave", permission: verificationPermission(REPORTE_AGRICULTURA) },
                                { name: "Calendario", id: 5306, icon: 'eco', route: "/agricultura/report/calendario", permission: verificationPermission(REPORTE_AGRICULTURA) },
                                { name: "Indice Lote", id: 5307, icon: 'eco', route: "/agricultura/report/indicelote", permission: verificationPermission(REPORTE_AGRICULTURA) },
                            ]
                    },
                    {
                        id: 504,
                        name: 'Mantenimientos',
                        children:
                            [
                                { name: "Zonas de Cultivo", id: 5401, icon: 'eco', route: "/agricultura/mantenimiento/lotegrupo", permission: verificationPermission(ZONAS_CULTIVO) },
                                { name: "Tipo Pico", id: 5402, icon: 'eco', route: "/agricultura/mantenimiento/tipopico", permission: verificationPermission(ZAFRA) },
                                { name: "Aplicador", id: 5403, icon: 'eco', route: "/agricultura/mantenimiento/aplicador", permission: verificationPermission(ZAFRA) },
                                { name: "Parcelas", id: 5404, icon: 'eco', route: "/agricultura/mantenimiento/parcelas", permission: verificationPermission(ZAFRA) },
                                { name: "Ingenieros", id: 5405, icon: 'eco', route: "/agricultura/mantenimiento/ingenieros", permission: verificationPermission(ZAFRA) },
                                { name: "Validacion", id: 5406, icon: 'eco', route: "/agricultura/mantenimiento/validacion", permission: verificationPermission(ZAFRA) },
                            ]
                    },
                    { name: "Configuraciones", id: 505, icon: 'settings', route: "/agricultura/settings", permission: verificationPermission(ZONAS_CULTIVO) },
                ]
        },
        {
            id: 6,
            name: "Ganaderia",
            children: [
                {
                    id: 601,
                    name: 'Animal',
                    children:
                        [

                            { id: 6101, name: "Animal", icon: 'pets', route: "/animal", permission: verificationPermission(ANIMAL) },
                            { id: 6110, name: "Pajuelas", icon: 'pets', route: "/pajuela", permission: verificationPermission(TERMO_SEMEN) },
                            { id: 6100, name: "Termo Semen", icon: 'pets', route: "/termosemen", permission: verificationPermission(TERMO_SEMEN) },
                            { id: 6102, name: "Celo", icon: 'pets', route: "/celo", permission: verificationPermission(CELO) },
                            { id: 6103, name: "Inseminacion", icon: 'pets', route: "/inseminacion", permission: verificationPermission(INSEMINACION) },
                            { id: 6104, name: "Palpacion", icon: 'pets', route: "/tacto", permission: verificationPermission(PALPACION) },
                            { id: 6105, name: "Secado", icon: 'pets', route: "/secado", permission: verificationPermission(SECADO) },
                            { id: 6106, name: "Preparto", icon: 'pets', route: "/preparto", permission: verificationPermission(PREPARTO) },
                            { id: 6107, name: "Parto", icon: 'pets', route: "/parto", permission: verificationPermission(PARTO) },
                            { id: 6108, name: "Venta Ganado", icon: 'pets', route: "/ventaganado", permission: verificationPermission(VENTA_GANADO) },
                        ]
                },
                {
                    id: 602,
                    name: "Salud",
                    children:
                        [
                            { id: 6201, name: "Aborto", icon: "local_hospital", route: "/aborto", permission: verificationPermission(ABORTO) },
                            { id: 6202, name: "Analisis", icon: "local_hospital", route: "/analisis", permission: verificationPermission(ANALISIS) },
                            { id: 6203, name: "Enfermedad", icon: "local_hospital", route: "/enfermedad", permission: verificationPermission(ENFERMEDAD) },
                            { id: 6204, name: "Medicacion", icon: "local_hospital", route: "/medicacion", permission: verificationPermission(MEDICACION) },
                            { id: 6205, name: "Muerte", icon: "local_hospital", route: "/muerte", permission: verificationPermission(MUERTE) },
                            { id: 6206, name: "Rechazo", icon: "local_hospital", route: "/rechazo", permission: verificationPermission(RECHAZO) },
                        ]
                },
                {
                    id: 603,
                    name: "Produccion",
                    children:
                        [
                            { id: 6301, name: "Produccion", icon: "trending_up", route: "/produccion", permission: verificationPermission(PRODUCCION_LECHE) },
                            { id: 6302, name: "Racion", icon: "trending_up", route: "/racion", permission: verificationPermission(RACION) },
                            { id: 6303, name: "Alimentacion", icon: "trending_up", route: "/alimentacion", permission: verificationPermission(ALIMENTACION) }
                        ]
                },
                {
                    id: 604,
                    name: "Reporte",
                    children:
                        [
                            { id: 6401, name: "Reproduccion", icon: "fact_check", route: "/reporteanimal/reproduccion", permission: verificationPermission(ANIMAL, PUEDE_GENERAR_REPORTE) },
                            { id: 6402, name: "Actividades", icon: "fact_check", route: "/reporteanimal/avanzado", permission: verificationPermission(ANIMAL, PUEDE_GENERAR_REPORTE) },
                            { id: 6403, name: "Ficha por Animal", icon: "fact_check", route: "/reporteanimal", permission: verificationPermission(ANIMAL, PUEDE_GENERAR_REPORTE) },
                            { id: 6404, name: "Indice Productivo", icon: "fact_check", route: "/reporteanimal/indiceproductivo", permission: verificationPermission(ANIMAL, PUEDE_GENERAR_REPORTE) },
                            { id: 6405, name: "Produccion Leche", icon: "fact_check", route: "/reporteproduccionanimal/reporteleche", permission: verificationPermission(PRODUCCION_LECHE, PUEDE_GENERAR_REPORTE) },
                            { id: 6406, name: "Produccion Animal", icon: "fact_check", route: "/reporteproduccionanimal/reporteanimal", permission: verificationPermission(PRODUCCION_LECHE, PUEDE_GENERAR_REPORTE) },
                        ]
                },
                { name: "Configuraciones", id: 605, icon: 'settings', route: "/gnconfig", permission: verificationPermission(ANIMAL) },
            ]
        },
        {
            id: 7,
            name: "Gastos",
            children: [
                {
                    id: 701,
                    name: "Gastos",
                    children:
                        [
                            { id: 7101, name: "Compras", icon: "add", route: "/compra", permission: verificationPermission(COMPRA) },
                            { id: 7102, name: "Egresos", icon: "add", route: "/egreso", permission: verificationPermission(EGRESO) },
                            { id: 7103, name: "Nota de Credito Compra", icon: "add", route: "/notacompra", permission: verificationPermission(NOTA_CREDITO_COMPRA) },
                        ]
                },
                {
                    id: 702,
                    name: "Pagos",
                    children:
                        [
                            { id: 7201, name: "Extracto de Proveedor", icon: "add", route: "/extractoproveedor", permission: verificationPermission(EXTRACTO_PROVEEDOR) },
                            { id: 7202, name: "Pago Cuenta", icon: "add", route: "/pagocuenta", permission: verificationPermission(PAGO_CUENTA) },
                        ]
                },
                {
                    id: 703,
                    name: "Reportes",
                    children:
                        [
                            { id: 7301, name: "Libro de Compras", icon: "add", route: "/librocompra", permission: verificationPermission(REPORTE_COMERCIAL, LIBRO_COMPRA) },
                        ]
                },
            ]
        },
        {
            id: 8,
            name: "Ventas",
            children: [
                {
                    id: 801,
                    name: "Ventas",
                    children:
                        [
                            { id: 8101, name: "Venta", icon: "shopping_cart", route: "/venta", permission: verificationPermission(VENTA) },
                            { id: 8102, name: "Preventa", icon: "shopping_cart", route: "/preventa", permission: verificationPermission(VENTA) },
                            { id: 8103, name: "Nota de Credito Venta", icon: "shopping_cart", route: "/notaventa", permission: verificationPermission(NOTA_CREDITO_VENTA) },
                        ]
                },
                {
                    id: 802,
                    name: "Cobros",
                    children:
                        [
                            { id: 8201, name: "Extracto de Cliente", icon: "shopping_cart", route: "/extractocliente", permission: verificationPermission(EXTRACTO_CLIENTE) },
                            { id: 8202, name: "Cobro Cuenta", icon: "shopping_cart", route: "/cobrocuenta", permission: verificationPermission(COBRO_CUENTA) },
                        ]
                },
                {
                    id: 803,
                    name: "Reportes",
                    children:
                        [
                            { id: 8300, name: "Extracto General", icon: "shopping_cart", route: "/venta/reportes/extractogeneral", permission: verificationPermission(EXTRACTO_CLIENTE) },
                            { id: 8301, name: "Extracto Resumido", icon: "shopping_cart", route: "/venta/reportes/extractoresumido", permission: verificationPermission(EXTRACTO_CLIENTE) },
                            { id: 8302, name: "Reportes Avanzados", icon: "shopping_cart", route: "/venta/reportes", permission: verificationPermission(REPORTE_COMERCIAL, LIBRO_VENTA) },
                            { id: 8303, name: "Libro de Ventas", icon: "shopping_cart", route: "/venta/reportes/libroventa", permission: verificationPermission(REPORTE_COMERCIAL, LIBRO_VENTA) },
                            { id: 8304, name: "Items Vendidas", icon: "shopping_cart", route: "/venta/reportes/itemventa", permission: verificationPermission(REPORTE_COMERCIAL, LIBRO_VENTA) },
                        ]
                }
            ]
        },
        {
            id: 14,
            name: "Maquinaria",
            children: [
                {
                    id: 1401,
                    name: "Inicio",
                    icon: 'dashboard',
                    route: '/maquinaria',
                    permission: verificationPermission(MAQUINARIA, PUEDE_LISTAR)
                },
                {
                    id: 1402,
                    name: "Maquinaria",
                    children: [
                        {
                            id: 140201,
                            name: "Maquinaria",
                            icon: "commute",
                            route: '/maquinaria/maquinaria/maquinaria',
                            permission: verificationPermission(MAQUINARIA, PUEDE_LISTAR)
                        },
                        {
                            id: 140202,
                            name: "Maquinaria Mantenimiento",
                            icon: "commute",
                            route: '/maquinaria/maquinaria/maquinariamantenimiento',
                            permission: verificationPermission(MANTENIMIENTO_MAQUINARIA, PUEDE_LISTAR)
                        },
                        {
                            id: 140203,
                            name: "Maquinaria Asignacion",
                            icon: "commute",
                            route: '/maquinaria/maquinaria/maquinariaasignacion',
                            permission: verificationPermission(ASIGNACION_MAQUINARIA, PUEDE_LISTAR)
                        },
                        {
                            id: 140204,
                            name: "Maquinaria Gasto",
                            icon: "commute",
                            route: '/maquinaria/maquinaria/maquinariagasto',
                            permission: verificationPermission(GASTO_MAQUINARIA, PUEDE_LISTAR)
                        },
                        {
                            id: 140205,
                            name: "Maquinaria Transporte",
                            icon: "commute",
                            route: '/maquinaria/maquinaria/maquinariatransporte',
                            permission: verificationPermission(TRANSPORTE_MAQUINARIA, PUEDE_LISTAR)
                        },
                        {
                            id: 140206,
                            name: "Maquinaria Salario",
                            icon: "commute",
                            route: '/maquinaria/maquinaria/maquinariasalario',
                            permission: verificationPermission(SALARIO_MAQUINARIA, PUEDE_LISTAR)
                        }
                    ]
                },
                {
                    id: 1403,
                    name: "Reporte",
                    children: [
                        { id: 140301, name: "Reporte", icon: 'build_circle', route: "/maquinaria/reporte/reporte", permission: verificationPermission(GASTO_MAQUINARIA) },
                    ]
                },
                {
                    id: 1404,
                    name: "Mantenimiento",
                    children: [
                        { id: 140401, name: "Tipo", icon: 'build_circle', route: "/maquinaria/mantenimiento/tipo", permission: verificationPermission(FABRICANTE) },
                        { id: 140402, name: "Fabricante", icon: 'build_circle', route: "/maquinaria/mantenimiento/fabricante", permission: verificationPermission(FABRICANTE) },
                        { id: 140403, name: "Tipo Mantenimiento", icon: 'build_circle', route: "/maquinaria/mantenimiento/tipomantenimiento", permission: verificationPermission(TIPO_MANTENIMIENTO_MAQUINARIA) },
                        { id: 140404, name: "Tipo Gasto Maquinaria", icon: 'build_circle', route: "/maquinaria/mantenimiento/tipogastomaquinaria", permission: verificationPermission(TIPO_GASTO_MAQUINARIA) }
                    ]
                },
                {
                    id: 1405,
                    name: "Configuracion Maquinaria",
                    icon: 'settings',
                    route: '/maquinaria/configuracion',
                    permission: verificationPermission(CONFIG_MAQUINARIA, PUEDE_LISTAR)
                },
            ]
        },
        {
            id: 12,
            name: "RRHH",
            children: [
                {
                    id: 1201,
                    name: "Inicio",
                    icon: 'dashboard',
                    route: '/rrhh',
                    permission: verificationPermission(FUNCIONARIO, PUEDE_LISTAR)
                },
                {
                    id: 1202,
                    name: "Funcionarios",
                    icon: 'groups',
                    route: '/rrhh/funcionarios',
                    permission: verificationPermission(FUNCIONARIO)
                },
                {
                    id: 1206,
                    name: "Asistencias",
                    icon: 'content_paste',
                    route: '/rrhh/asistencias',
                    permission: verificationPermission(ASISTENCIA)
                },
                {
                    id: 1204,
                    name: 'Pagos',
                    children:
                        [
                            { id: 12400, name: "Salario", icon: 'monetization_on', route: "/rrhh/pago/salario", permission: verificationPermission(PAGO_SALARIO) },
                            { id: 12403, name: "Adelanto", icon: 'monetization_on', route: "/rrhh/pago/adelanto", permission: verificationPermission(PAGO_SALARIO) },
                            { id: 12401, name: "Aguinaldo", icon: 'monetization_on', route: "/rrhh/pago/aguinaldo", permission: verificationPermission(PAGO_SALARIO) },
                            { id: 12402, name: "Despido/Renuncia", icon: 'monetization_on', route: "/rrhh/pago/despido", permission: verificationPermission(PAGO_SALARIO) },
                        ]
                },
                {
                    id: 1207,
                    name: 'Operaciones',
                    children:
                        [
                            { id: 12700, name: "Ausencias/Vacaciones", icon: 'publish', route: "/rrhh/operacion/ausencias", permission: verificationPermission(JUSTIFICACIONES) },

                        ]
                },
                {
                    id: 1203,
                    name: 'Mantenimientos',
                    children:
                        [
                            { id: 12300, name: "Sector", icon: 'build_circle', route: "/rrhh/mantenimiento/sector", permission: verificationPermission(SECTOR) },
                            { id: 12301, name: "Nivel", icon: 'build_circle', route: "/rrhh/mantenimiento/nivel", permission: verificationPermission(NIVEL) },
                            { id: 12302, name: "Concepto Salarial", icon: 'build_circle', route: "/rrhh/mantenimiento/concepto", permission: verificationPermission(CONCEPTO_SALARIAL) },
                            { id: 12303, name: "Estado Asistencia", icon: 'build_circle', route: "/rrhh/mantenimiento/estadoasistencia", permission: verificationPermission(ESTADO_ASISTENCIA) },
                            { id: 12304, name: "Reloj Biometrico", icon: 'build_circle', route: "/rrhh/mantenimiento/relojbio", permission: verificationPermission(RELOJ_BIOMETRICO) },
                            { id: 12305, name: "Poligono de Marcacion", icon: 'build_circle', route: "/rrhh/mantenimiento/poligono", permission: verificationPermission(POLIGONO_MARCACION) }
                        ]
                },
                {
                    id: 1208,
                    name: "Reporte RRHH",
                    icon: "pie_chart",
                    route: "/rrhh/reporterrhh",
                    permission: verificationPermission(FUNCIONARIO)
                },
                {
                    id: 1205,
                    name: "Configuraciones",
                    icon: 'settings',
                    route: '/rrhh/settings',
                    permission: verificationPermission(CONFIG_RRHH)
                }
            ]
        },
        {
            id: 13,
            name: "Gimnasio",
            children: [
                {
                    id: 1300,
                    name: "Planilla Gimnasio",
                    icon: "add",
                    route: "/Gimnasio/planillagimnasio",
                    permission: verificationPermission(TIPO_GIMNASIO)
                },
                {
                    id: 1301,
                    name: "Ajustes Gimnasio",
                    children: [
                        {
                            id: 130101, name: "Tipo Gimnasio", icon: "add", route: "/Gimnasio/mantenimiento/tipogimnasio", permission: verificationPermission(TIPO_GIMNASIO)
                        }
                    ]
                }
            ]
        },
        {
            id: 9,
            name: "Finanzas",
            children: [
                {
                    id: 900,
                    name: "Mi Finanza",
                    icon: "dashboard",
                    route: "/finanzas/mifinanza", permission: verificationPermission(FINANZA)
                },
                {
                    id: 901,
                    name: "Reportes",
                    children:
                        [
                            { id: 9101, name: "Reportes Comercial", icon: "add", route: "/reportecomercial/estadoresultado", permission: verificationPermission(REPORTE_COMERCIAL, ESTADO_RESULTADO) },
                        ]
                },
                {
                    id: 902,
                    name: "Operaciones",
                    children:
                        [
                            { id: 9201, name: "Apertura Caja", icon: "shopping_cart", route: "/apertura", permission: verificationPermission(APERTURA_CAJA) },
                            { id: 9202, name: "Cotizacion", icon: "add", route: "/cotizacion", permission: verificationPermission(COTIZACION) },
                            { id: 9203, name: "Tipo Finanza", icon: "add", route: "/finanzas/operaciones/tipo", permission: verificationPermission(FINANZA) },
                            { id: 9204, name: "Tiempo Finanza", icon: "add", route: "/finanzas/operaciones/tiempo", permission: verificationPermission(FINANZA) },
                            { id: 9205, name: "Cuenta Finanza", icon: "add", route: "/finanzas/operaciones/cuenta", permission: verificationPermission(FINANZA) },
                            { id: 9206, name: "Grupo Finanza", icon: "add", route: "/finanzas/operaciones/grupo", permission: verificationPermission(FINANZA) },
                            { id: 9207, name: "Categoria Finanza", icon: "add", route: "/finanzas/operaciones/categoria", permission: verificationPermission(FINANZA) },
                        ]
                },
                {
                    id: 903,
                    name: "Transacciones",
                    children:
                        [
                            { id: 9301, name: "Movimiento", icon: "add", route: "/finanzas/transacciones/movimiento", permission: verificationPermission(FINANZA) },
                        ]
                },
                {
                    id: 904,
                    name: "Finanzas Reportes",
                    children:
                        [
                            { id: 9401, name: "Gastos", icon: "add", route: "/finanzas/reportes/gastos", permission: verificationPermission(FINANZA) },
                            { id: 9402, name: "Ingresos", icon: "add", route: "/finanzas/reportes/ingresos", permission: verificationPermission(FINANZA) },
                            { id: 9403, name: "Presupuestos", icon: "add", route: "/finanzas/reportes/presupuestos", permission: verificationPermission(FINANZA) },
                            { id: 9404, name: "Flujo de Cajas", icon: "add", route: "/finanzas/reportes/flujos", permission: verificationPermission(FINANZA) },
                        ]
                }
            ]
        },
        {
            id: 10,
            name: "Stock",
            children: [
                { id: 1001, name: "Reporte Stock", icon: "add", route: "/stock", permission: verificationPermission(STOCK) },
                { id: 1002, name: "Ajuste Stock", icon: "add", route: "/ajuste", permission: verificationPermission(AJUSTE_STOCK) },
                { id: 1003, name: "Transferencia", icon: "add", route: "/transferencia", permission: verificationPermission(TRANSFERENCIA) },
            ]
        },
        {
            id: 11,
            name: "Ajustes",
            children: [

                {
                    id: 1101,
                    name: "Ganaderia",
                    children:
                        [
                            {
                                id: 110101,
                                name: "Animal Ajustes",
                                children:
                                    [
                                        { id: 11010101, name: "Costo Nacimiento", icon: "add", route: "/costonacimiento", permission: verificationPermission(COSTO_NACIMIENTO) },
                                        { id: 11010102, name: "Estado Cria", icon: "add", route: "/estadocria", permission: verificationPermission(ESTADO_CRIA) },
                                        { id: 11010104, name: "Lote", icon: "add", route: "/lote", permission: verificationPermission(LOTE) },
                                        { id: 11010105, name: "Procedencia", icon: "add", route: "/procedencia", permission: verificationPermission(PROCEDENCIA) },
                                        { id: 11010106, name: "Raza", icon: "add", route: "/raza", permission: verificationPermission(RAZA) },
                                        { id: 11010107, name: "Responsable", icon: "add", route: "/responsable", permission: verificationPermission(RESPONSABLE) },
                                        { id: 11010108, name: "Rol", icon: "add", route: "/rol", permission: verificationPermission(ROL) },
                                        { id: 11010109, name: "Tipo Parto", icon: "add", route: "/tipoparto", permission: verificationPermission(TIPO_PARTO) },
                                        { id: 110101010, name: "Tipo responsable", icon: "add", route: "/tiporesponsable", permission: verificationPermission(TIPO_RESPONSABLE) },
                                        { id: 110101011, name: "Causa no Inseminacion", icon: "add", route: "/causanoinseminacion", permission: verificationPermission(CAUSA_NO_INSEMINACION) },
                                    ]
                            },
                            {
                                id: 110102,
                                name: "Salud Ajustes",
                                children:
                                    [
                                        { id: 11010201, name: "Tipo Analisis", icon: "add", route: "/tipoanalisis", permission: verificationPermission(TIPO_ANALISIS) },
                                        { id: 11010202, name: "Veterinario", icon: "add", route: "/veterinario", permission: verificationPermission(VETERINARIO) },
                                        { id: 11010203, name: "Via Aplicacion", icon: "add", route: "/viaaplicacion", permission: verificationPermission(VIA_APLICACION) },
                                        { id: 11010204, name: "Medicacion Motivo", icon: "add", route: "/medicacionmotivo", permission: verificationPermission(MEDICACION_MOTIVO) },
                                        { id: 11010205, name: "Causa Aborto", icon: "add", route: "/causaaborto", permission: verificationPermission(CAUSA_ABORTO) },
                                        { id: 11010206, name: "Causa Rechazo", icon: "add", route: "/causarechazo", permission: verificationPermission(CAUSA_RECHAZO) },
                                        { id: 11010207, name: "Diagnostico Utero", icon: "add", route: "/diagnosticoutero", permission: verificationPermission(DIAGNOSTICO_UTERO) },
                                        { id: 11010208, name: "Motivo Muerte", icon: "add", route: "/motivomuerte", permission: verificationPermission(MOTIVO_MUERTE) },
                                        { id: 11010209, name: "Tipo Enfermedad", icon: "add", route: "/tipoenfermedad", permission: verificationPermission(TIPO_ENFERMEDAD) }
                                    ]
                            },
                            {
                                id: 110103,
                                name: "Produccion Ajustes",
                                children:
                                    [
                                        { id: 11010301, name: "Turno", icon: "add", route: "/turno", permission: verificationPermission(TURNO_ORDENO) }
                                    ]
                            },
                        ]
                },
                /**-------------------------------------------------------------------------------------------------------- */
                {
                    id: 1103,
                    name: "Ventas",
                    children:
                        [
                            {
                                id: 110301,
                                name: "Ventas Ajustes",
                                children:
                                    [
                                        { id: 11030101, name: "Timbrado", icon: "add", route: "/timbrado", permission: verificationPermission(TIMBRADO) },
                                        { id: 11030102, name: "Establecimiento", icon: "add", route: "/establecimiento", permission: verificationPermission(ESTABLECIMIENTO) },
                                        { id: 11030103, name: "Punto de Venta", icon: "add", route: "/puntoventa", permission: verificationPermission(PUNTO_VENTA) },
                                        { id: 11030104, name: "Punto Expedicion", icon: "add", route: "/puntoexp", permission: verificationPermission(PUNTO_EXPEDICION) },
                                        { id: 11030105, name: "Configuracion de Impresion", icon: "add", route: "/configimpresion", permission: verificationPermission(CONFIG_IMPRESION) },
                                        { id: 11030106, name: "Perfil Financiamiento", icon: "add", route: "/perfilfin", permission: verificationPermission(PERFIL_FINANCIAMIENTO) }
                                    ]
                            },

                        ]
                },
                /**-------------------------------------------------------------------------------------------------------- */
                {
                    id: 1104,
                    name: "Stock",
                    children:
                        [
                            {
                                id: 110401,
                                name: "Stock Ajustes",
                                children:
                                    [
                                        { id: 11040101, name: "Tipo Ajuste", icon: "add", route: "/tipoajuste", permission: verificationPermission(TIPO_AJUSTE) },
                                        { id: 11040102, name: "Motivo Ajuste", icon: "add", route: "/motivoajuste", permission: verificationPermission(MOTIVO_AJUSTE) },
                                        { id: 11040103, name: "Motivo Transferencia", icon: "add", route: "/motivotraslado", permission: verificationPermission(MOTIVO_TRANSLADO) }
                                    ]
                            },
                        ]
                },
                /**-------------------------------------------------------------------------------------------------------- */
                {
                    id: 1105,
                    name: "Finanzas",
                    children:
                        [
                            {
                                id: 110501,
                                name: "Finanzas Ajustes",
                                children:
                                    [
                                        { id: 11050101, name: "Banco", icon: "add", route: "/banco", permission: verificationPermission(BANCO) },
                                        { id: 11050102, name: "Caja", icon: "add", route: "/caja", permission: verificationPermission(CAJA) },
                                        { id: 11050103, name: "Cuenta Banco", icon: "add", route: "/cuentabanco", permission: verificationPermission(CUENTA_BANCO) },
                                        { id: 11050104, name: "Tipo Cuenta", icon: "add", route: "/tipocuenta", permission: verificationPermission(TIPO_CUENTA_BANCO) },
                                    ]
                            },

                        ]
                },
                /**-------------------------------------------------------------------------------------------------------- */
                {
                    id: 1106,
                    name: "Items",
                    children:
                        [
                            {
                                id: 110601,
                                name: "Items Ajustes",
                                children:
                                    [
                                        { id: 11060101, name: "Item", icon: "add", route: "/item", permission: verificationPermission(ITEM) },
                                        { id: 11060102, name: "Propiedad Quimica", icon: "add", route: "/propiedadquimica", permission: verificationPermission(PROPIEDAD_QUIMICA) },
                                        { id: 11060103, name: "Concepto", icon: "add", route: "/concepto", permission: verificationPermission(CONCEPTO) },
                                        { id: 11060104, name: "Marca", icon: "add", route: "/marca", permission: verificationPermission(MARCA) },
                                        { id: 11060105, name: "Categoria", icon: "add", route: "/categoria", permission: verificationPermission(CATEGORIA_ITEM) },
                                        { id: 11060106, name: "Deposito", icon: "add", route: "/deposito", permission: verificationPermission(DEPOSITO) },
                                        { id: 11060107, name: "Cuenta", icon: "add", route: "/cuenta", permission: verificationPermission(CUENTA) },
                                        { id: 11060108, name: "Objetivo", icon: "add", route: "/objetivo", permission: verificationPermission(OBJETIVO) },
                                    ]
                            },
                        ]
                },
                {
                    id: 1107,
                    name: "Personas",
                    children:
                        [
                            {
                                id: 110701,
                                name: "Personas Ajustes",
                                children:
                                    [
                                        { id: 11070101, name: "Contacto", icon: "add", route: "/contacto", permission: true },
                                        { id: 11070102, name: "Usuario", icon: "add", route: "/usuarioempresa", permission: verificationPermission(USUARIO) },
                                    ]
                            },
                        ]
                },

                {
                    id: 1108,
                    name: "Mantenimientos",
                    children:
                        [
                            {
                                id: 110801,
                                name: "Mantenimientos Ajustes",
                                children:
                                    [
                                        { id: 11080101, name: "Ciudad", icon: "add", route: "/ciudad", permission: true },
                                        { id: 11080102, name: "Nacionalidad", icon: "add", route: "/nacionalidad", permission: true },
                                        { id: 11080103, name: "Empresa", icon: "add", route: "/empresa", permission: verificationPermission(EMPRESA) },
                                        { id: 11080104, name: "Rol de Usuario", icon: "add", route: "/rolusuario", permission: verificationPermission(ROL_USUARIO) },
                                        { id: 11080105, name: "Sucursal", icon: "add", route: "/tambo", permission: verificationPermission(SUCURSAL) }
                                    ]
                            },
                            {
                                id: 110901,
                                name: "Sistema",
                                children:
                                    [
                                        { id: 11090101, name: "Magnitud", icon: "add", route: "/magnitud", permission: verificationPermission(ITEM) },
                                        { id: 11090102, name: "Unidad de Medida", icon: "add", route: "/unidadmedida", permission: verificationPermission(ITEM) },
                                    ]
                            },
                        ]
                },
            ]
        }

    ]
    return array.reduce((accumulator, current) => {
        if (current.children) {
            const children = current.children.reduce((accumulator1, current1) => {
                if (current1.children) {
                    const children1 = current1.children.reduce((accumulator2, current2) => {
                        if (current2.children) {
                            const children2 = current2.children.filter(child => {
                                return child.permission === true
                            })
                            if (children2.length)
                                accumulator2.push({
                                    ...current2,
                                    children: [...children2],
                                });
                            return accumulator2;
                        }
                        else {
                            if (current2.permission)
                                accumulator2.push({
                                    ...current2,
                                });
                            return accumulator2;
                        }
                    }, []);
                    if (children1.length)
                        accumulator1.push({
                            ...current1,
                            children: [...children1],
                        });
                    return accumulator1;
                } else {
                    if (current1.permission)
                        accumulator1.push({
                            ...current1,
                        });
                    return accumulator1;
                }
            }, []);
            if (children.length)
                accumulator.push({
                    ...current,
                    children: [...children],
                });
            return accumulator;
        } else {
            if (current.permission)
                accumulator.push({
                    ...current,
                });
            return accumulator;
        }
    }, []);

}


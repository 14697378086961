<template>
    <div>
        <div class="d-md-flex flex-row justify-space-between">
            <div class="title hidden-sm-and-down">Finanza</div>
            <v-sheet class="d-flex justify-end mb-2">
                <v-slide-group multiple show-arrows>
                    <v-slide-item><v-btn active-class="deep-purple--text accent-4" exact text tile
                        to="/finanzas/mifinanza">Mi Finanza</v-btn></v-slide-item>
                    <v-slide-item>
                        <v-menu bottom left offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text tile class="align-self-center" v-bind="attrs" v-on="on">
                                    Mantenimientos
                                    <v-icon right>arrow_drop_down</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item v-for="(sub, subkey) in menu.mantenimiento" :key="subkey" :to="sub.route"
                                    active-class="deep-purple--text accent-4">{{ sub.title }}</v-list-item>
                            </v-list>
                        </v-menu>
                    </v-slide-item>
                    <v-slide-item>
                        <v-menu bottom left offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text tile class="align-self-center" v-bind="attrs" v-on="on">
                                    Transaccion
                                    <v-icon right>arrow_drop_down</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item v-for="(sub1, subkey1) in menu.transacciones" :key="subkey1"
                                    :to="sub1.route" active-class="deep-purple--text accent-4">{{ sub1.title
                                    }}</v-list-item>
                            </v-list>
                        </v-menu>
                    </v-slide-item>
                    <v-slide-item>
                        <v-menu bottom left offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text tile class="align-self-center" v-bind="attrs" v-on="on">
                                    Finanzas Reportes
                                    <v-icon right>arrow_drop_down</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item v-for="(sub1, subkey1) in menu.reportes" :key="subkey1" :to="sub1.route"
                                    active-class="deep-purple--text accent-4">{{ sub1.title
                                    }}</v-list-item>
                            </v-list>
                        </v-menu>
                    </v-slide-item>
                </v-slide-group>
            </v-sheet>
        </div>
        <v-divider></v-divider>
        <transition>
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </transition>
    </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { FINANZA } from "@/constants/forms";
import { PUEDE_LISTAR } from "@/constants/permissions";

export default {
    data() {
        return {
            permission: {
                can_list: false,
            },
            menu: {
                mantenimiento: [
                    {
                        title: "Tipo",
                        route: "/finanzas/operaciones/tipo"
                    },
                    {
                        title: "Tiempo",
                        route: "/finanzas/operaciones/tiempo"
                    },
                    {
                        title: "Cuenta",
                        route: "/finanzas/operaciones/cuenta"
                    },
                    {
                        title: "Grupo",
                        route: "/finanzas/operaciones/grupo"
                    },
                    {
                        title: "Categoria",
                        route: "/finanzas/operaciones/categoria"
                    },
                ],
                transacciones: [
                    {
                        title: "Movimiento",
                        route: "/finanzas/transacciones/movimiento"
                    },
                ],
                reportes: [
                    {
                        title: "Gasto",
                        route: "/finanzas/reportes/gastos"
                    },
                    {
                        title: "Ingresos",
                        route: "/finanzas/reportes/ingresos"
                    },
                    {
                        title: "Presupuestos",
                        route: "/finanzas/reportes/presupuestos"
                    },
                    {
                        title: "Flujo de Caja",
                        route: "/finanzas/reportes/flujos"
                    },
                ],
            }
        }
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
    },
    mounted() {
        const permission = getPermission(FINANZA).permiso;
        this.permission = {
            can_list: permission[PUEDE_LISTAR],
        };
    },
}
</script>
<template>
    <div>
        <Help v-model="help" :content="todos" />
        <v-alert type="error" v-if="permission_alert">
            Error! Este usuario no tiene permiso en esta ventana
        </v-alert>
        <v-toolbar flat dense>
            <v-toolbar-items>
                <v-btn class="ml-2" small color="primary" id="aceptar" outlined @click="descargarDatos()">
                    <v-icon>cloud_download</v-icon>
                </v-btn>
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <c-btn-table-help @click="help = true"></c-btn-table-help>
            </v-toolbar-items>
        </v-toolbar>
        <v-tabs v-model="years" fixed-tabs center-active show-arrows>
            <v-tab v-for="y in getFlujoYear" :key="y" @click="fetchFlujoCaja({ year: y })">{{ y }}</v-tab>
        </v-tabs>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">

                        </th>
                        <th class="text-right">
                            Enero
                        </th>
                        <th class="text-right">
                            Febrero
                        </th>
                        <th class="text-right">
                            Marzo
                        </th>
                        <th class="text-right">
                            Abril
                        </th>
                        <th class="text-right">
                            Mayo
                        </th>
                        <th class="text-right">
                            Junio
                        </th>
                        <th class="text-right">
                            Julio
                        </th>
                        <th class="text-right">
                            Agosto
                        </th>
                        <th class="text-right">
                            Septiembre
                        </th>
                        <th class="text-right">
                            Octubre
                        </th>
                        <th class="text-right">
                            Noviembre
                        </th>
                        <th class="text-right">
                            Diciembre
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="green">
                        <td colspan="13" class="font-weight-bold">Ingresos</td>
                    </tr>
                    <tr v-for="ing in ingresos" :key="ing.id">
                        <td v-for="i in Object.keys(ing)" :key="i" :class="i != `name` ? `text-right` : ``">{{ i == `name` ? ing[i] : toCurrency(ing[i]) }}</td>
                    </tr>
                    <tr class="green darken-4 font-weight-bold">
                        <td>Total Ingresos (+)</td>
                        <td class="text-right" v-for="i in Object.keys(totingresos)" :key="i">{{ toCurrency(totingresos[i]) }}</td>
                    </tr>
                    <tr>
                        <td colspan="13"></td>
                    </tr>
                    <tr class="orange">
                        <td colspan="13" class="font-weight-bold">Gastos Generales</td>
                    </tr>
                    <tr v-for="gas in egresos" :key="gas.id">
                        <td v-for="g in Object.keys(gas)" :key="g" :class="g != `name` ? `text-right` : ``">{{ g == `name` ? gas[g] : toCurrency(gas[g]) }}</td>
                    </tr>
                    <tr class="deep-orange font-weight-bold">
                        <td>Total Egresos (-)</td>
                        <td class="text-right" v-for="i in Object.keys(totegresos)" :key="i">{{ toCurrency(totegresos[i]) }}</td>
                    </tr>
                    <tr>
                        <td colspan="13"></td>
                    </tr>
                    <tr class="info">
                        <td colspan="13" class="font-weight-bold">Saldos Acumulados (A inicio de mes)</td>
                    </tr>
                    <tr v-for="sal in saldos" :key="sal.id">
                        <td v-for="s in Object.keys(sal)" :key="s" :class="s == `name` ? `font-weight-bold` : `text-right`">{{ s == `name` ? sal[s] : toCurrency(sal[s]) }}</td>
                    </tr>
                    <tr class="blue darken-4 font-weight-bold">
                        <td>Total Acumulado (+)</td>
                        <td class="text-right" v-for="i in Object.keys(totsaldos)" :key="i">{{ toCurrency(totsaldos[i]) }}</td>
                    </tr>
                    <tr>
                        <td colspan="13"></td>
                    </tr>
                    <tr>
                        <td>Ingresos</td>
                        <td class="text-right" v-for="i in Object.keys(totingresos)" :key="i">{{ toCurrency(totingresos[i]) }}</td>
                    </tr>
                    <tr>
                        <td>Saldos</td>
                        <td class="text-right" v-for="i in Object.keys(totsaldos)" :key="i">{{ toCurrency(totsaldos[i]) }}</td>
                    </tr>
                    <tr>
                        <td>Egresos</td>
                        <td class="text-right" v-for="i in Object.keys(totegresos)" :key="i">{{ toCurrency(totegresos[i]) }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="text-right font-weight-bold" v-for="i in Object.keys(total)" :key="i">{{ toCurrency(total[i]) }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { currency } from "@/services/util/number.service";
import { mensaje } from "@/services/notify.service";
import { FINANZA } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";
import Help from "../Help.vue";
import { REPORTES } from "@/constants/finanzas";
import { mapActions, mapGetters } from "vuex";
export default {
    components: {
        Help,
    },
    computed: {
        ...mapGetters("finanza", ["getFlujoCaja", "isLoadingFlujoCaja", "getFlujoYear", "isLoadingFlujoYear"]),
        permission_alert() {
            return mensaje.permission(this.permission);
        },
    },
    watch: {
        getFlujoCaja(val) {
            this.ingresos = val.ingresos
            this.egresos = val.egresos
            this.saldos = val.saldos
            this.totalOperations(val.ingresos, this.totingresos)
            this.totalOperations(val.egresos, this.totegresos)
            this.totalOperations(val.saldos, this.totsaldos)
            this.finalOperations(this.total, this.totingresos, this.totsaldos, this.totegresos)
        },
        getFlujoYear(val) {
            if (val.length >= 1) {
                this.fetchFlujoCaja({ year: val[val.length -1] })
            }
        }
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
        this.todos = REPORTES
        const permission = getPermission(FINANZA).permiso;
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR],
        };
        this.fetchFlujoYear()
    },
    methods: {
        ...mapActions("finanza", ["fetchFlujoCaja", "fetchFlujoYear"]),
        toCurrency(value) {
            return currency(value)
        },
        totalOperations(dato, total) {
            Object.keys(total).forEach(function(k){
                let total2 = 0
                dato.forEach(ingr => {
                    total2 += ingr[k]
                })
                total[k] = total2
            }) 
        },
        finalOperations(fin, val1, val2, val3) {
            Object.keys(fin).forEach(function(k){
                fin[k] = val1[k] + val2[k] - val3[k]
            })
        }
    },
    data: () => ({
        years: null,
        months: null,
        help: false,
        todos: null,
        load: false,
        items: [],
        permission: {
            can_add: false,
            can_view: false,
        },
        length: 15,
        tab: null,
        ingresos: [
        ],
        egresos: [
        ],
        saldos: [
        ],
        totingresos: {
            enero: 0,
            febrero: 0,
            marzo: 0,
            abril: 0,
            mayo: 0,
            junio: 0,
            julio: 0,
            agosto: 0,
            septiembre: 0,
            octubre: 0,
            noviembre: 0,
            diciembre: 0,
        },
        totegresos: {
            enero: 0,
            febrero: 0,
            marzo: 0,
            abril: 0,
            mayo: 0,
            junio: 0,
            julio: 0,
            agosto: 0,
            septiembre: 0,
            octubre: 0,
            noviembre: 0,
            diciembre: 0,
        },
        totsaldos: {
            enero: 0,
            febrero: 0,
            marzo: 0,
            abril: 0,
            mayo: 0,
            junio: 0,
            julio: 0,
            agosto: 0,
            septiembre: 0,
            octubre: 0,
            noviembre: 0,
            diciembre: 0,
        },
        total: {
            enero: 0,
            febrero: 0,
            marzo: 0,
            abril: 0,
            mayo: 0,
            junio: 0,
            julio: 0,
            agosto: 0,
            septiembre: 0,
            octubre: 0,
            noviembre: 0,
            diciembre: 0,
        },
    }),
}
</script>
<style lang="css">
.numeric {
    text-align: right
}
</style>